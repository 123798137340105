import React, { Component } from "react";

class ConnectForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      feedbackMessage: "",
      feedbackType: ""
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, feedbackMessage: "", feedbackType: "" });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', this.state);

    const { name, email, message } = this.state;

    try {
      const response = await fetch('https://oynkxdh3tg.execute-api.us-east-1.amazonaws.com/v1/connect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, message })
      });

      if (response.ok) {
        // Handle successful response
        this.setState({ 
          name: "", 
          email: "", 
          message: "", 
          feedbackMessage: "I will contact you in a bit :)",
          feedbackType: "success"
        });
      } else {
        // Handle error response
        this.setState({ 
          feedbackMessage: "Sorry, something is wrong, can you try re-connect?",
          feedbackType: "error"
        });
      }
    } catch (error) {
      this.setState({ 
        feedbackMessage: "Sorry, something is wrong, can you try re-connect?",
        feedbackType: "error"
      });
    }
  };

  render() {
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.connect;
      // var header = this.props.resumeConnect.description_header;
      // var about = this.props.resumeConnect.description;
      var yourName = this.props.resumeConnect.your_name;
      var yourEmail = this.props.resumeConnect.your_email;
      var yourMessage = this.props.resumeConnect.your_message;
      var connect = this.props.resumeConnect.connect;
    }

    return (
      <section id="connect" className="connect-form">
        <div className="col-md-12">
          <h1 style={{ color: "black" }} className="section-title">
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            {/* <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                <span style={{ cursor: "auto" }}>
                  <img
                    height="250px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <Icon
                    icon={angularIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={reactIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                  <Icon
                    icon={vueIcon}
                    style={{ fontSize: "400%", margin: "9% 5% 0 5%" }}
                  />
                </span>
              </div>
            </div> */}

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="emojione:red-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:yellow-circle"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                    <span
                      className="iconify"
                      data-icon="twemoji:green-circle"
                      data-inline="false"
                    ></span>
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    {/* <br />
                    <span className="wave">{header} :) </span>
                    <br /> */}
                    <br />
                    {/* {about} */}

                    <form onSubmit={this.handleSubmit} className="form">
                        <div className="form-group">
                        <label htmlFor="name">{yourName}</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            required
                            className="input"
                        />
                        </div>
                        <div className="form-group">
                        <label htmlFor="email">{yourEmail}</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            required
                            className="input"
                        />
                        </div>
                        <div className="form-group">
                        <label htmlFor="message">{yourMessage}</label>
                        <textarea
                            id="message"
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            required
                            className="input textarea"
                        />
                        </div>
                        <button type="submit" className="connect-button">{connect}</button>
                    </form>
                    {this.state.feedbackMessage && (
                      <div className={`feedback-message ${this.state.feedbackType}`}>
                        {this.state.feedbackMessage}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ConnectForm;
